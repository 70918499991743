export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["PRIMARY"] = "primary";
    ButtonVariant["PRIMARY_ALT"] = "primary-alt";
    ButtonVariant["SECONDARY"] = "secondary";
    ButtonVariant["WARN"] = "warn";
    ButtonVariant["TEXT"] = "text";
    ButtonVariant["TEXT_ALT"] = "text-alt";
})(ButtonVariant || (ButtonVariant = {}));
export var ButtonValue;
(function (ButtonValue) {
    ButtonValue[ButtonValue["SMALL"] = 25] = "SMALL";
    ButtonValue[ButtonValue["LARGE"] = 50] = "LARGE";
    ButtonValue[ButtonValue["EXTRA_LARGE"] = 75] = "EXTRA_LARGE";
})(ButtonValue || (ButtonValue = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["MEDIUM"] = "medium";
    ButtonSize["LARGE"] = "large";
    ButtonSize["SMALL"] = "small";
})(ButtonSize || (ButtonSize = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["BUTTON"] = "button";
    ButtonType["SUBMIT"] = "submit";
    ButtonType["RESET"] = "reset";
})(ButtonType || (ButtonType = {}));
export var DropdownPlacement;
(function (DropdownPlacement) {
    DropdownPlacement["BOTTOM_RIGHT"] = "bottom-right";
    DropdownPlacement["BOTTOM_LEFT"] = "bottom-left";
})(DropdownPlacement || (DropdownPlacement = {}));
