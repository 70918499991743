import { EY_CLOUD } from 'common/const';
import { AuthMode, PolicyMode } from 'common/enums';
const envSettings = window;
const EnvList = {
    Prod: 'prod',
    Dev: 'dev',
    Local: 'local',
    QA: 'qa',
    SIT: 'sit',
    Perf: 'perf'
};
const getApplicationVariables = () => {
    let appVariables;
    try {
        appVariables = JSON.parse(atob(`${envSettings.APP_VARIABLES || process.env.APP_VARIABLES}`));
    }
    catch (e) {
        appVariables = {
            REACT_APP_CLIENT_ID: '',
            REACT_APP_AUTHORITY: '',
            REACT_APP_REDIRECT_URI: '',
            REACT_APP_API_URI: '',
            REACT_APP_GLOBAL_API_ENDPOINT: '',
            REACT_APP_CLIENT_API_ENDPOINT: '',
            REACT_APP_API_SCOPE: '',
            AUTH_MODE: '',
            REACT_APP_DISABLE_SSO: '',
            REACT_APP_ENABLE_SUBSCRIPTIONS: '',
            REACT_APP_CLIENT_ASSESSMENT_API_ENDPOINT: '',
            REACT_APP_CONTROL_ASSESSMENT_API_ENDPOINT: '',
            REACT_APP_IRP_API_ENDPOINT: '',
            REACT_APP_DISABLE_POLICY: '',
            REACT_APP_POLICY_MODE: '',
            REACT_APP_ENABLE_PAANS: '',
            REACT_APP_ENABLE_REGION_ENDPOINT: '',
            REACT_APP_KEYCLOAK_URL: '',
            REACT_APP_KEYCLOAK_REALM: '',
            REACT_APP_KEYCLOAK_CLIENT_ID: '',
            REACT_APP_KEYCLOAK_REDIRECT_URI: '',
            REACT_APP_NOTIFICATION_API_ENDPOINT: '',
            APP_VERSION: '',
            APP_VARIABLES: '',
            APP_VERSION_DATE: '',
            APP_FEATURES: '',
            APP_ENV: '',
            TLM_SERVER_URL: '',
            TRANSLATION_LANGUAGE: '',
            TLM_CONTAINER_NAME: '',
            DEPLOYMENT_ENVIRONMENT: '',
            REACT_APP_AUTH0_DOMAIN: '',
            REACT_APP_AUTH0_AUDIENCE: '',
            REACT_APP_AUTH0_CLIENT_ID: '',
            REACT_APP_AUDIT_API_ENDPOINT: ''
        };
    }
    return appVariables;
};
const appVariables = getApplicationVariables();
export const ENV_CONFIG = {
    BUILD_ID: process.env.REACT_APP_BUILD_NO,
    CLIENT_ID: appVariables.REACT_APP_CLIENT_ID,
    AUTHORITY: appVariables.REACT_APP_AUTHORITY,
    REDIRECT_URI: `${appVariables.REACT_APP_REDIRECT_URI || window.location.origin}`,
    CACHE_LOCATION: 'sessionStorage',
    AUTH_MODE: appVariables.AUTH_MODE || AuthMode.AAD,
    IDLE_TIME: 12,
    IS_DEV_ENV: process.env.NODE_ENV === 'development',
    API: appVariables.REACT_APP_API_URI || `${window.location.origin}/}`,
    API_SCOPE: appVariables.REACT_APP_API_SCOPE,
    GLOBAL_ENDPOINT: appVariables.REACT_APP_GLOBAL_API_ENDPOINT,
    CLIENT_ENDPOINT: appVariables.REACT_APP_CLIENT_API_ENDPOINT,
    AUTH0_DOMAIN: appVariables.REACT_APP_AUTH0_DOMAIN,
    AUTH0_AUDIENCE: appVariables.REACT_APP_AUTH0_AUDIENCE,
    AUTH0_CLIENT_ID: appVariables.REACT_APP_AUTH0_CLIENT_ID,
    KEYCLOAK_URL: appVariables.REACT_APP_KEYCLOAK_URL,
    KEYCLOAK_REALM: appVariables.REACT_APP_KEYCLOAK_REALM,
    KEYCLOAK_CLIENT_ID: appVariables.REACT_APP_KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REDIRECT_URI: appVariables.REACT_APP_KEYCLOAK_REDIRECT_URI,
    AUDIT_ENDPOINT: appVariables.REACT_APP_AUDIT_API_ENDPOINT,
    CLIENT_ASSESSMENT_ENDPOINT: appVariables.REACT_APP_CLIENT_ASSESSMENT_API_ENDPOINT,
    CONTROL_ASSESSMENT_ENDPOINT: appVariables.REACT_APP_CONTROL_ASSESSMENT_API_ENDPOINT,
    IRP_ENDPOINT: appVariables.REACT_APP_IRP_API_ENDPOINT,
    NOTIFICATION_ENDPOINT: appVariables.REACT_APP_NOTIFICATION_API_ENDPOINT,
    DISABLE_SSO: appVariables.REACT_APP_DISABLE_SSO === 'active',
    ENABLE_SUBSCRIPTIONS: appVariables.REACT_APP_ENABLE_SUBSCRIPTIONS === 'active',
    DISABLE_POLICY: appVariables.REACT_APP_DISABLE_POLICY === 'active',
    POLICY_MODE: appVariables.REACT_APP_POLICY_MODE || PolicyMode.CUSTOM,
    ENABLE_PAANS: appVariables.REACT_APP_ENABLE_PAANS === 'active',
    IS_DEPLOYMENT_ENV_EYCLOUD: appVariables.DEPLOYMENT_ENVIRONMENT === EY_CLOUD,
    ENABLE_REGION_ENDPOINT: appVariables.REACT_APP_ENABLE_REGION_ENDPOINT === 'active',
    APP_VERSION: appVariables.APP_VERSION,
    APP_VERSION_DATE: appVariables.APP_VERSION_DATE,
    APP_FEATURES: appVariables.APP_FEATURES || '{}',
    APP_ENV: appVariables.APP_ENV,
    IS_PROD_ENV: appVariables.APP_ENV === EnvList.Prod,
    APP_IRL_HOST: envSettings.APP_IRL_HOST || process.env.APP_IRL_HOST || '{}',
    APP_HOST_URL: `${envSettings.APP_HOST_URL || process.env.APP_HOST_URL}`,
    APP_RISK_ACCELERATOR_HOST: envSettings.APP_RISK_ACCELERATOR_HOST || process.env.APP_RISK_ACCELERATOR_HOST || '{}',
    APP_FINDINGS_HOST: envSettings.APP_FINDINGS_HOST || process.env.APP_FINDINGS_HOST || '{}',
    TLM_SERVER_URL: appVariables.TLM_SERVER_URL,
    TRANSLATION_LANGUAGE: appVariables.TRANSLATION_LANGUAGE,
    TLM_CONTAINER_NAME: appVariables.TLM_CONTAINER_NAME
};
