export var ToastVariant;
(function (ToastVariant) {
    ToastVariant["INFO"] = "info";
    ToastVariant["WARNING"] = "warning";
    ToastVariant["ERROR"] = "error";
    ToastVariant["SUCCESS"] = "success";
})(ToastVariant || (ToastVariant = {}));
export var ToastPosition;
(function (ToastPosition) {
    ToastPosition["TOP"] = "top";
    ToastPosition["BOTTOM"] = "bottom";
})(ToastPosition || (ToastPosition = {}));
