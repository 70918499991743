export var ChipVariant;
(function (ChipVariant) {
    ChipVariant["SUCCESS"] = "success";
    ChipVariant["WARNING"] = "warning";
    ChipVariant["ERROR"] = "error";
    ChipVariant["MONOCHROME_LIGHT"] = "monochrome-light";
    ChipVariant["MONOCHROME_DARK"] = "monochrome-dark";
})(ChipVariant || (ChipVariant = {}));
export var ChipVariantCss;
(function (ChipVariantCss) {
    ChipVariantCss["SUCCESS"] = "motif-chip-color-success";
    ChipVariantCss["WARNING"] = "motif-chip-color-warning";
    ChipVariantCss["MONOCHROME_DARK"] = "motif-chip-color-manual";
})(ChipVariantCss || (ChipVariantCss = {}));
export var BadgeVariant;
(function (BadgeVariant) {
    BadgeVariant["SUCCESS"] = "success";
    BadgeVariant["WARNING"] = "warning";
    BadgeVariant["ERROR"] = "error";
    BadgeVariant["ALT"] = "alt";
    BadgeVariant["INFO"] = "info";
})(BadgeVariant || (BadgeVariant = {}));
export var ChipType;
(function (ChipType) {
    ChipType["CHIP"] = "chip";
    ChipType["DOT"] = "dot";
    ChipType["OUTLINED"] = "outlined";
    ChipType["RAG"] = "rag";
})(ChipType || (ChipType = {}));
export var ChipColorNA;
(function (ChipColorNA) {
    ChipColorNA["NA"] = "#c4c4cd";
})(ChipColorNA || (ChipColorNA = {}));
