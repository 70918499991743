export var ROLE;
(function (ROLE) {
    ROLE[ROLE["NONE"] = 0] = "NONE";
    ROLE[ROLE["ADMIN"] = 1] = "ADMIN";
    ROLE[ROLE["CLIENT"] = 2] = "CLIENT";
})(ROLE || (ROLE = {}));
export var UserRole;
(function (UserRole) {
    UserRole["ASSESSOR"] = "ASR";
    UserRole["QUALITY_CONTROL_1"] = "QC1";
    UserRole["QUALITY_CONTROL_2"] = "QC2";
    UserRole["RELATIONSHIP_MANAGER"] = "RM";
    UserRole["THIRD_PARTY"] = "TP";
    UserRole["QUEUE_MANAGER"] = "QM";
    UserRole["THIRD_PARTY_RISK_MANAGER"] = "TPRM";
    UserRole["ADMIN"] = "ADM";
    UserRole["CLIENT_ADMIN"] = "CADM";
})(UserRole || (UserRole = {}));
export var UserRoleTitle;
(function (UserRoleTitle) {
    UserRoleTitle["NONE"] = "none";
    UserRoleTitle["ASSESSOR"] = "Assessor";
    UserRoleTitle["QUALITY_CONTROL_1"] = "Quality Control 1";
    UserRoleTitle["QUALITY_CONTROL_2"] = "Quality Control 2";
    UserRoleTitle["RELATIONSHIP_MANAGER"] = "Relationship Manager";
    UserRoleTitle["THIRD_PARTY"] = "Third Party";
    UserRoleTitle["CLIENT_ADMIN"] = "Client Admin";
    UserRoleTitle["QUEUE_MANAGER"] = "Queue Manager";
    UserRoleTitle["CLIENT_THIRD_PARTY_RISK_MANAGER"] = "Client Third Party Risk Manager";
})(UserRoleTitle || (UserRoleTitle = {}));
export var RoleId;
(function (RoleId) {
    RoleId[RoleId["ADMIN"] = 1] = "ADMIN";
    RoleId[RoleId["ASSESSOR"] = 2] = "ASSESSOR";
    RoleId[RoleId["THIRD_PARTY_RISK_MANAGER"] = 3] = "THIRD_PARTY_RISK_MANAGER";
    RoleId[RoleId["QUALITY_CONTROL_1"] = 4] = "QUALITY_CONTROL_1";
    RoleId[RoleId["QUALITY_CONTROL_2"] = 5] = "QUALITY_CONTROL_2";
    RoleId[RoleId["QUEUE_MANAGER"] = 6] = "QUEUE_MANAGER";
    RoleId[RoleId["RELATIONSHIP_MANAGER"] = 7] = "RELATIONSHIP_MANAGER";
    RoleId[RoleId["THIRD_PARTY"] = 8] = "THIRD_PARTY";
    RoleId[RoleId["CLIENT_ADMIN"] = 9] = "CLIENT_ADMIN";
})(RoleId || (RoleId = {}));
