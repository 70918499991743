import { ApiStatus } from './api-status.enum';
import { ROLE } from './role.enum';
export { AvatarSize } from './controls/avatar.enum';
export { ButtonSize, ButtonType, ButtonVariant } from './controls/button.enum';
export { BadgeVariant, ChipVariant, ChipVariantCss } from './controls/chip.enum';
export { PAGE_CONFIG, PAGINATION_CONFIG } from './controls/pagination.enum';
export { InputType } from './controls/input.enum';
export { ModalPlacement, ModalSize, ModalVariant } from './controls/modal.enum';
export { ToastVariant, ToastPosition } from './controls/toast.enum';
export { ProgressIndicatorStatus, ProgressIndicatorVariant } from './controls/progress-indicator.enum';
export { ApiStatus, ROLE };
export { TabVariant } from './controls/content-tab.enum';
// Enum values assigned here is the keys of the 'breadcrumb' object from common.json
export var BREADCRUMB;
(function (BREADCRUMB) {
    BREADCRUMB["NONE"] = "none";
    BREADCRUMB["DASHBOARD"] = "dashboard";
    BREADCRUMB["MANAGE_TEAM"] = "manageTeam";
    BREADCRUMB["MANAGE_ADMIN"] = "manageAdmin";
    BREADCRUMB["IRP"] = "irp";
    BREADCRUMB["THIRD_PARTIES"] = "thirdParties";
    BREADCRUMB["ASSESSMENTS"] = "assessments";
    BREADCRUMB["REPORTING"] = "reporting";
    BREADCRUMB["VENDOR_SUMMARY"] = "vendorSummary";
    BREADCRUMB["SUMMARY"] = "summary";
    BREADCRUMB["ADMIN_SETTINGS"] = "adminSettings";
    BREADCRUMB["GENERAL_INFORMATION"] = "generalInformation";
    BREADCRUMB["MANAGE_SUBSCRIPTIONS"] = "manageSubscription";
    BREADCRUMB["TEMPLATE_MANAGEMENT"] = "templateManagement";
    BREADCRUMB["CONFIGURATION_MANAGEMENT"] = "configurationManagement";
    BREADCRUMB["JOB_MONITORING"] = "jobMonitoring";
    BREADCRUMB["FINDINGS"] = "findings";
    BREADCRUMB["CHANGE_LOG"] = "changeLog";
    BREADCRUMB["INTEGRATED_RISK_LIBRARY"] = "irl";
    BREADCRUMB["CONTROL_ASSESSMENT"] = "controlassessments";
})(BREADCRUMB || (BREADCRUMB = {}));
export var BREADCRUMB_LEVEL;
(function (BREADCRUMB_LEVEL) {
    BREADCRUMB_LEVEL[BREADCRUMB_LEVEL["FIRST"] = 1] = "FIRST";
    BREADCRUMB_LEVEL[BREADCRUMB_LEVEL["SECOND"] = 2] = "SECOND";
    BREADCRUMB_LEVEL[BREADCRUMB_LEVEL["THIRD"] = 3] = "THIRD";
    BREADCRUMB_LEVEL[BREADCRUMB_LEVEL["FOURTH"] = 4] = "FOURTH";
})(BREADCRUMB_LEVEL || (BREADCRUMB_LEVEL = {}));
export var GraphFilter;
(function (GraphFilter) {
    GraphFilter["OR"] = "OR";
})(GraphFilter || (GraphFilter = {}));
export var GraphUser;
(function (GraphUser) {
    GraphUser["DISPLAY_NAME"] = "displayName";
    GraphUser["ID"] = "id";
    GraphUser["MAIL"] = "mail";
})(GraphUser || (GraphUser = {}));
export var SORT_DIRECTION;
(function (SORT_DIRECTION) {
    SORT_DIRECTION[SORT_DIRECTION["ASC"] = 1] = "ASC";
    SORT_DIRECTION[SORT_DIRECTION["DESC"] = 2] = "DESC";
})(SORT_DIRECTION || (SORT_DIRECTION = {}));
export var SORT_DIRECTION_STR;
(function (SORT_DIRECTION_STR) {
    SORT_DIRECTION_STR["ASC"] = "asc";
    SORT_DIRECTION_STR["DESC"] = "desc";
})(SORT_DIRECTION_STR || (SORT_DIRECTION_STR = {}));
export var BaseSort;
(function (BaseSort) {
    BaseSort["Name"] = "name";
    BaseSort["CreatedDate"] = "createdUtcDate";
    BaseSort["UpdatedDate"] = "updatedUtcDate";
    BaseSort["DueUtcDate"] = "dueUtcDate";
    BaseSort["InherentRiskProfileID"] = "inherentRiskProfileId";
    BaseSort["Type"] = "vendorType";
})(BaseSort || (BaseSort = {}));
export var FileType;
(function (FileType) {
    FileType["JPEG"] = "image/jpeg";
})(FileType || (FileType = {}));
export var FIELD_TYPE;
(function (FIELD_TYPE) {
    FIELD_TYPE[FIELD_TYPE["TEXT"] = 1] = "TEXT";
    FIELD_TYPE[FIELD_TYPE["TEXT_AREA"] = 2] = "TEXT_AREA";
    FIELD_TYPE[FIELD_TYPE["RADIO"] = 3] = "RADIO";
    FIELD_TYPE[FIELD_TYPE["DATE"] = 4] = "DATE";
    FIELD_TYPE[FIELD_TYPE["CHECKBOX"] = 5] = "CHECKBOX";
    FIELD_TYPE[FIELD_TYPE["SELECT"] = 6] = "SELECT";
    FIELD_TYPE[FIELD_TYPE["MULTI_SELECT"] = 7] = "MULTI_SELECT";
    FIELD_TYPE[FIELD_TYPE["OTHER"] = 8] = "OTHER";
    FIELD_TYPE[FIELD_TYPE["SEARCH"] = 9] = "SEARCH";
    FIELD_TYPE[FIELD_TYPE["CURRENCY"] = 10] = "CURRENCY";
    FIELD_TYPE[FIELD_TYPE["PHONENUMBER"] = 11] = "PHONENUMBER";
    FIELD_TYPE[FIELD_TYPE["USER_SEARCH"] = 12] = "USER_SEARCH";
})(FIELD_TYPE || (FIELD_TYPE = {}));
export var ResponseErrorCode;
(function (ResponseErrorCode) {
    ResponseErrorCode["SUCCESS"] = "Success";
    ResponseErrorCode["SUCCESS_ALT"] = "SUCCESS";
    ResponseErrorCode["ERRORCLIENTDOESNTEXIST"] = "ErrorClientDoesntExist";
    ResponseErrorCode["ERRORUSERDOESNTEXIST"] = "ErrorUserDoesntExist";
    ResponseErrorCode["ERROR_THIRD_PARTY_NAME_ALREADY_EXIST"] = "ThirdPartyAlreadyExists";
    ResponseErrorCode["CLIENTUSERMAPALREADYEXIST"] = "ClientUserMapAlreadyExist";
    ResponseErrorCode["CLIENTUSERROLEMAPALREADYEXIST"] = "ClientUserRoleMapAlreadyExist";
    ResponseErrorCode["USERISADMIN"] = "UserIsAdmin";
    ResponseErrorCode["CLIENTAPICALLERROR"] = "ClientApiCallError";
    ResponseErrorCode["ERRORSAVINGDATAINDB"] = "ErrorSavingDataInDb";
    ResponseErrorCode["ROLEDOESNTEXIST"] = "RoleDoesntExist";
    ResponseErrorCode["PENDINGTASKEXIST"] = "PendingTaskExist";
    ResponseErrorCode["SERVICENOTCREATED"] = "ServiceNotCreated";
    ResponseErrorCode["SERVICEALREADYEXIST"] = "ServiceAlreadyExist";
    ResponseErrorCode["NOT_CREATED_IN_EY_ASSESS_SERVICE"] = "NotCreatedInEyAssessService";
    ResponseErrorCode["FAILED"] = "Failed";
    ResponseErrorCode["PARTIAL_SUCCESS"] = "PartialSuccess";
    ResponseErrorCode["ROLECONFLICT"] = "RoleConflict";
    ResponseErrorCode["CLIENTCONFLICT"] = "ClientConflict";
    ResponseErrorCode["NAMEREPEATED"] = "NameRepeated";
    ResponseErrorCode["NOTCREATEDINDB1"] = "NotCreatedInDb 1";
    ResponseErrorCode["NOTCREATEDINDB"] = "NotCreatedInDb";
    ResponseErrorCode["SPECIALCHARACTERSNOTALLOWED"] = "SpecialCharactersNotAllowed";
    ResponseErrorCode["UNKNOWN"] = "Unknown";
    ResponseErrorCode["UNAUTHORIZED"] = "Unauthorized";
    ResponseErrorCode["TEMPLATE_IN_USE"] = "TemplateInUse";
    ResponseErrorCode["TEMPLATE_NOT_FOUND"] = "TemplateNotFound";
    ResponseErrorCode["MISSING_TAB_INFO"] = "MissingTabInfo";
    ResponseErrorCode["CONTROL_ASSESSMENT_INITIATED"] = "ControlAssessmentInitiated";
    ResponseErrorCode["CONTROL_ASSESSMENT_COMPLETED"] = "ControlAssessmentCompleted";
    ResponseErrorCode["IN_QM_REVIEW"] = "InQMReview";
    ResponseErrorCode["IRP_APPROVED"] = "IRPApproved";
    ResponseErrorCode["EYASSESS_ERROR"] = "EyAssessError";
    ResponseErrorCode["USER_ALREADY_EXISTS"] = "UserAlreadyExists";
    ResponseErrorCode["USER_ALREADY_EXISTS_IN_AZUREAD"] = "UserAlreadyExistsInAzureAd";
    ResponseErrorCode["TEMPLATE_NOT_PUBLISHED"] = "TemplateNotPublished";
    ResponseErrorCode["USER_NOT_FOUND_AS_ADMIN"] = "UserNotFoundAsAdmin";
    ResponseErrorCode["NOT_ALLOWED_TO_DELETE_ADMIN"] = "NotAllowedToDeleteAdmin";
    ResponseErrorCode["VALIDATION_ERROR"] = "ValidationError";
    ResponseErrorCode["ROWS_EXCEEDED"] = "NumberOfRowsExceedsLimit";
    ResponseErrorCode["NO_ASSESSMENT_NEEDED"] = "NoAssessmentNeeded";
    ResponseErrorCode["KICK_OFF_DATE_PAST_DATE"] = "KickOffDatePastDate";
    ResponseErrorCode["ASSESSMENT_STATUS_NOT_ALLOWED_FOR_KICK_OFF_DATE"] = "ErrorAssessmentStatusNotAllowedToSaveKickOffDate";
    ResponseErrorCode["ERROR_ASSESSMENT_SCOPING_DOESNT_EXIST"] = "AssessmentScopingDoesNotExist";
    ResponseErrorCode["IN_CORRECT_EMAIL_DOMAIN"] = "InCorrectEmailDomain";
    ResponseErrorCode["TP_PRIMARY_CONTACT_MANDATORY"] = "TpContactMandatory";
    ResponseErrorCode["TP_PRIMARY_CONTACT_MANDATORY_SUBMIT_EY"] = "TP_CONTACT_MANDATORY";
    ResponseErrorCode["DUPLICATE_ASSESSMENT_USER"] = "DuplicateAssessmentUser";
    ResponseErrorCode["NO_OTHER_USER_FOR_ROLE"] = "NoOtherUserForRole";
    ResponseErrorCode["FILE_UPLOAD_EMPTY_FILE"] = "EmptyFile";
    ResponseErrorCode["UNABLE_TO_CANCEL_IRP"] = "UnableToCancelIrp";
    ResponseErrorCode["UNABLE_TO_CANCEL_ASSESSMENT"] = "UnableToCancelAssessment";
    ResponseErrorCode["INACTIVE_ASSESSMENT_TEMPLATE"] = "AssessmentTemplateDoesNotExistOrInActive";
    ResponseErrorCode["ERROR_ADDING_USER_IN_MYEY"] = "ErrorAddingUserInMyEY";
    ResponseErrorCode["ERROR_EMAIL_ALREADY_REGISTERED"] = "ErrorEmailAlreadyRegistered";
    ResponseErrorCode["ERROR_INVALID_EMAIL_DOMAIN"] = "ErrorInvalidEmailDomain";
    ResponseErrorCode["EXCEED_FILE_SIZE_LIMIT"] = "UploadedFileTypeOrFileSizeNotAllowed";
    ResponseErrorCode["CLIENT_ADMIN_CANNOT_EDIT"] = "ClientAdminCannotEdit";
    ResponseErrorCode["CLIENT_ADMIN_CANNOT_ADD"] = "ClientAdminCannotAdd";
    ResponseErrorCode["CLIENT_ADMIN_CANNOT_DELETE"] = "ClientAdminCannotDelete";
    ResponseErrorCode["CLIENT_ADMIN_PERMISSION_DENIED"] = "ClientAdminPermissionDenied";
    ResponseErrorCode["CLIENT_ADMIN_CANNOT_SELF_DELETE"] = "ClientAdminCannotSelfDelete";
    ResponseErrorCode["CLIENT_ADMIN_CANNOT_SELF_EDIT"] = "ClientAdminCannotSelfEdit";
    ResponseErrorCode["USER_ROLE_CONFLICT_EXTERNAL"] = "UserRoleConflictExternal";
    ResponseErrorCode["USER_ROLE_CONFLICT_INTERNAL"] = "UserRoleConflictInternal";
    ResponseErrorCode["USER_IS_QUEUE_MANAGER"] = "UserIsQueueManager";
    ResponseErrorCode["USER_CANNOT_BE_QUEUE_MANAGER"] = "UserCannotBeQueueManager";
    ResponseErrorCode["NO_ACTIVE_TEMPLATE_FOUND"] = "NoActiveTemplateFound";
    ResponseErrorCode["NO_ACTIVE_TEMPLATE_FOUND_SUBMIT_EY"] = "NO_ACTIVE_TEMPLATE_FOUND";
    ResponseErrorCode["INVALID_TEMPLATE"] = "InvalidTemplate";
    ResponseErrorCode["ERROR_ASSESSMENT_STATUS_ALREADY_EXIST"] = "ErrorForAssessmentStatusAlreadyExist";
    ResponseErrorCode["ERROR_ASSESSMENT_SCOPE_ALREADY_EXIST"] = "ErrorForAssessmentScopeAlreadyExist";
    ResponseErrorCode["FILE_OVERWRITTEN"] = "FileOverwritten";
    ResponseErrorCode["TEMPLATE_NO_SECTION_INSCOPE"] = "TemplateNoSectionInScope";
    ResponseErrorCode["TEMPLATE_NO_QUESTION_InSCOPE"] = "TemplateNoQuestionInScope";
    ResponseErrorCode["TEMPLATE_SUM_SCOPE_NOT100"] = "TemplateSumScopeNot100";
    ResponseErrorCode["TEMPLATE_SUM_QUESTIONS_NOT_EQUAL_SCOPE"] = "TemplateSumQuestionsNotEqualScope";
    ResponseErrorCode["INVALID_TEMPLATE_SECTION_QUESTION_CONFIGURATION"] = "InvalidTemplateSectionQuestionConfiguration";
    ResponseErrorCode["INVALID_DOMAIN"] = "InvalidDomain";
    ResponseErrorCode["USER_IS_PRIMARY_THIRD_PARTY"] = "UserIsPrimaryThirdParty";
    ResponseErrorCode["EVIDENCE_LIMIT_EXCEEDED"] = "EvidenceLimitExceeded";
    ResponseErrorCode["FOLLOW_UP_NOT_COMPLETED"] = "FollowUpNotCompleted";
    ResponseErrorCode["ERROR_CA_DOES_NOT_EXIST"] = "ErrorControlAssessmentDoesntExist";
    ResponseErrorCode["CANNOT_DELEGATE_TO_SAME_USER"] = "CannotDelegateToSameUser";
    ResponseErrorCode["USERALREADYEXISTSINIRL"] = "UserAlreadyExistsInIrl";
    ResponseErrorCode["SCOPE_CHANGE_NOT_ALLOWED"] = "ScopeChangeNotAllowed";
    ResponseErrorCode["INVALID_DATA"] = "InvalidData";
    ResponseErrorCode["ASSESSMENT_NOT_COMPLETED"] = "AssessmentNotCompleted";
    ResponseErrorCode["FILENAME_EXCEEDS_LIMIT"] = "FileNameExceedsLimit";
    ResponseErrorCode["INVALID_STATE_ASSESSMENT"] = "InvalidStateForAssessment";
    ResponseErrorCode["NO_DATA"] = "NoData";
    ResponseErrorCode["NO_FINDINGS_FOR_ASSESSMENT"] = "NoFindingsForAssessment";
})(ResponseErrorCode || (ResponseErrorCode = {}));
export var KeyCodes;
(function (KeyCodes) {
    KeyCodes["ENTER_KEY"] = "Enter";
})(KeyCodes || (KeyCodes = {}));
export var EventKey;
(function (EventKey) {
    EventKey["BEFOREUNLOAD"] = "beforeunload";
})(EventKey || (EventKey = {}));
export var ThirdPartyKey;
(function (ThirdPartyKey) {
    ThirdPartyKey["THIRD_PARTY_NAME"] = "thirdpPrtyName";
    ThirdPartyKey["RELATIONSHIP_MANAGER"] = "relationshipManager";
    ThirdPartyKey["DUE_DATE"] = "dueDate";
    ThirdPartyKey["WEBSITE"] = "website";
    ThirdPartyKey["TIER"] = "tier";
    ThirdPartyKey["THIRD_PARTY_TYPE"] = "thirdPartyType";
    ThirdPartyKey["ADDRESS_LINE1"] = "addressLine1";
    ThirdPartyKey["ADDRESS_LINE2"] = "addressLine2";
    ThirdPartyKey["COUNTRY"] = "country";
    ThirdPartyKey["STATE"] = "state";
    ThirdPartyKey["CITY"] = "city";
    ThirdPartyKey["ZIPCODE"] = "zipcode";
    ThirdPartyKey["ADDITIONAL_LOCATION_INFO"] = "additionalLocationInformation";
    ThirdPartyKey["CONTACT_NAME"] = "contactName";
    ThirdPartyKey["EMAIL"] = "email";
    ThirdPartyKey["PHONE_NUMBER"] = "phoneNumber";
})(ThirdPartyKey || (ThirdPartyKey = {}));
export var ServiceKey;
(function (ServiceKey) {
    ServiceKey["SERVICE_NAME"] = "serviceName";
    ServiceKey["SERVICE_TYPE"] = "serviceType";
    ServiceKey["SUB_SERVICE_TYPE"] = "subServiceType";
    ServiceKey["SERVICE_DESCRIPTION"] = "serviceDescription";
    ServiceKey["BUSINESS_UNIT"] = "businessUnit";
    ServiceKey["BUSINESS_EXECUTIVE_CONTACT"] = "businessExecutiveContact";
})(ServiceKey || (ServiceKey = {}));
export var ProductKey;
(function (ProductKey) {
    ProductKey["PRODUCT_NAME"] = "productName";
    ProductKey["PRODUCT_TYPE"] = "productType";
    ProductKey["SUB_PRODUCT_TYPE"] = "subProductType";
    ProductKey["PRODUCT_DESCRIPTION"] = "productDescription";
    ProductKey["BUSINESS_UNIT"] = "businessUnit";
    ProductKey["BUSINESS_EXECUTIVE_CONTACT"] = "businessExecutiveContact";
})(ProductKey || (ProductKey = {}));
export var ThirdPartyServiceKey;
(function (ThirdPartyServiceKey) {
    ThirdPartyServiceKey["SERVICE_NAME"] = "serviceName";
    ThirdPartyServiceKey["SERVICE_TYPE"] = "serviceType";
    ThirdPartyServiceKey["SUB_SERVICE_TYPE"] = "subServiceType";
    ThirdPartyServiceKey["SERVICE_DESCRIPTION"] = "serviceDescription";
    ThirdPartyServiceKey["BUSINESS_UNIT"] = "businessUnit";
    ThirdPartyServiceKey["BUSINESS_EXECUTIVE_CONTACT"] = "businessExecutiveContact";
    ThirdPartyServiceKey["START_AND_END_DATE"] = "startEndDate";
    ThirdPartyServiceKey["SPENDANUAL"] = "spendAnual";
    ThirdPartyServiceKey["ADDRESS1"] = "address1";
    ThirdPartyServiceKey["ADDRESS2"] = "address2";
    ThirdPartyServiceKey["COUNTRY"] = "serviceCountry";
    ThirdPartyServiceKey["STATE"] = "serviceState";
    ThirdPartyServiceKey["CITY"] = "serviceCity";
    ThirdPartyServiceKey["ZIPCODE"] = "serviceZipcode";
    ThirdPartyServiceKey["ADDITIONAL_LOCATION_INFO"] = "serviceAdditionalLocationInformation";
})(ThirdPartyServiceKey || (ThirdPartyServiceKey = {}));
export const DEFAULT_TEMPLATE_CHARACTER_LENGTH = 30;
export var ButtonStepper;
(function (ButtonStepper) {
    ButtonStepper["PREV"] = "prev";
    ButtonStepper["NEXT"] = "next";
})(ButtonStepper || (ButtonStepper = {}));
export var ThirdPartyLength;
(function (ThirdPartyLength) {
    ThirdPartyLength[ThirdPartyLength["MAX_NAVIGATION"] = 11] = "MAX_NAVIGATION";
    ThirdPartyLength[ThirdPartyLength["MAX_ERROR"] = 10] = "MAX_ERROR";
})(ThirdPartyLength || (ThirdPartyLength = {}));
export var AuthMode;
(function (AuthMode) {
    AuthMode["AAD"] = "AAD";
    AuthMode["AUTH0"] = "AUTH0";
    AuthMode["KEYCLOAK"] = "KEYCLOAK";
})(AuthMode || (AuthMode = {}));
export var PolicyMode;
(function (PolicyMode) {
    PolicyMode["PAANS"] = "PAANS";
    PolicyMode["CUSTOM"] = "CUSTOM";
})(PolicyMode || (PolicyMode = {}));
export var LoaderType;
(function (LoaderType) {
    LoaderType["YES"] = "Yes";
    LoaderType["NO"] = "No";
    LoaderType["DEFAULT"] = "";
})(LoaderType || (LoaderType = {}));
export var PromptAction;
(function (PromptAction) {
    PromptAction["NONE"] = "none";
    PromptAction["SAVE"] = "Save";
    PromptAction["CANCEL"] = "Cancel";
    PromptAction["DISCARD"] = "Discard";
})(PromptAction || (PromptAction = {}));
export var ActiveModule;
(function (ActiveModule) {
    ActiveModule["NONE"] = "none";
    ActiveModule["PROFILE_SCOPING"] = "profileScoping";
})(ActiveModule || (ActiveModule = {}));
export var ActiveModuleState;
(function (ActiveModuleState) {
    ActiveModuleState["NONE"] = "none";
    ActiveModuleState["INPROGRESS"] = "in-progress";
    ActiveModuleState["COMPLETED"] = "completed";
})(ActiveModuleState || (ActiveModuleState = {}));
export var SessionCodeName;
(function (SessionCodeName) {
    SessionCodeName["MULTIPLE"] = "Multiple";
})(SessionCodeName || (SessionCodeName = {}));
export var GenericStatus;
(function (GenericStatus) {
    GenericStatus[GenericStatus["NONE"] = 1] = "NONE";
    GenericStatus[GenericStatus["PENDING"] = 2] = "PENDING";
    GenericStatus[GenericStatus["COMPLETED"] = 3] = "COMPLETED";
})(GenericStatus || (GenericStatus = {}));
export * from './file.enum';
export * from './activity-log.enum';
