export var ModalVariant;
(function (ModalVariant) {
    ModalVariant["DEFAULT"] = "default";
    ModalVariant["ALT"] = "alt";
    ModalVariant["SLIDEOUT"] = "slideout";
})(ModalVariant || (ModalVariant = {}));
export var ModalPlacement;
(function (ModalPlacement) {
    ModalPlacement["DEFAULT"] = "default";
    ModalPlacement["SLIDE_OUT"] = "slideout";
})(ModalPlacement || (ModalPlacement = {}));
export var ModalSize;
(function (ModalSize) {
    ModalSize["DEFAULT"] = "default";
    ModalSize["SMALL"] = "sm";
    ModalSize["MEDIUM"] = "md";
    ModalSize["LARGE"] = "lg";
    ModalSize["EXTRA_LARGE"] = "xl";
    ModalSize["EXTRA_EXTRA_LARGE"] = "xxl";
    ModalSize["EXTRA_EXTRA_EXTRA_LARGE"] = "xxxl";
})(ModalSize || (ModalSize = {}));
