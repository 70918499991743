import { ENV_CONFIG } from 'common/config/env.config';
// For the deployment Micro frontend host need to be configured at runtime.
// It is done with with the help of `external-remotes-plugin` package.
// Above mentioned package is solution from module federation team
// Ref - https://github.com/module-federation/module-federation-examples/issues/566
const getIRLHost = () => {
    let irlHost;
    try {
        irlHost = JSON.parse(atob(ENV_CONFIG.APP_IRL_HOST));
    }
    catch (e) {
        irlHost = { irlFrontEnd: '', irlService: '' };
    }
    return irlHost;
};
const getRiskAcceleratorHost = () => {
    let riskAcceleratorHost;
    try {
        riskAcceleratorHost = JSON.parse(atob(ENV_CONFIG.APP_RISK_ACCELERATOR_HOST));
    }
    catch (e) {
        riskAcceleratorHost = {
            reportingFrontEnd: '',
            reportingService: ''
        };
    }
    return riskAcceleratorHost;
};
const getFindingsHost = () => {
    let findingsHost;
    try {
        findingsHost = JSON.parse(atob(ENV_CONFIG.APP_FINDINGS_HOST));
    }
    catch (e) {
        findingsHost = {
            findingsFrontEnd: '',
            findingsService: '',
            stage: ''
        };
    }
    return findingsHost;
};
const irlHost = getIRLHost();
window.irlFrontEnd = irlHost.irlFrontEnd;
window.irlService = irlHost.irlService;
const riskAcceleratorHost = getRiskAcceleratorHost();
window.reportingFrontEnd = riskAcceleratorHost.reportingFrontEnd;
window.reportingService = riskAcceleratorHost.reportingService;
const findingsHost = getFindingsHost();
window.findingsHost = findingsHost.findingsFrontEnd;
window.findingsFrontEnd = findingsHost.findingsFrontEnd;
window.findingsStage = findingsHost.stage;
import('./bootstrap');
