export const MAX_SEARCH_RESULT = '20';
export const CLIENT_ENGAGEMENTS_MAX_SEARCH_RESULT = '500';
export const MAX_CHAR_LIMIT_MODAL = 200;
export const MIN_CHANGELOG_FILTER_DATE = 6;
export const EY_CLOUD = 'EY-Cloud';
export const SUPPORT_EMAIL_CONTENT = `mailto:gmsservicedesk@ey.com?subject=EY Support&body=
Hi, I'm facing the below issue...%0D%0A %0D%0A
Offering: EY TPRP__%0D%0A
Client: <provide the client name associated with this issue> %0D%0A
Engagement: EY TPRP %0D%0A
Username: %0D%0A
Email ID: %0D%0A
Contact Number: %0D%0A
Region/Country: %0D%0A
Issue description: %0D%0A
Screen shot: %0D%0A %0D%0A
Please do not change pre-populated information on the below fields: %0D%0A %0D%0A
Offering, Client and Engagement`;
export const HELP_DESK_DATA = [
    { Country: 'Australia', ContactNumber: '1 800 512 431' },
    { Country: 'Canada', ContactNumber: '1 833 926 1952' },
    { Country: 'Cyprus', ContactNumber: '800 93493' },
    { Country: 'Denmark', ContactNumber: '80 253 989' },
    { Country: 'Estonia', ContactNumber: '8000 100 848' },
    { Country: 'Finland', ContactNumber: '0 800 916 089' },
    { Country: 'Germany', ContactNumber: '0 800 180 4003' },
    { Country: 'Ireland', ContactNumber: '1 800 947 230' },
    { Country: 'Italy', ContactNumber: '800 872 163' },
    { Country: 'Netherlands', ContactNumber: '0 8000 249 888' },
    { Country: 'New Zealand', ContactNumber: '0 800 498 740' },
    { Country: 'Singapore', ContactNumber: '800 101 4218' },
    { Country: 'South Africa', ContactNumber: '0 800 994 990' },
    { Country: 'Switzerland', ContactNumber: '0 800 893 163' },
    { Country: 'United Kingdom', ContactNumber: '0 800 0489 949' },
    { Country: 'United States', ContactNumber: '888 281 4296' }
];
export const PRIORITY_SUPPORT_DATA = [
    {
        priority: '1 - Critical',
        definition: 'Service is down or unavailable with no viable workaround.P1 incidents may only be created for production issues.',
        level: [
            'Initial response within 1 hour of incident report to EY through official channel',
            'Continuous effort initiated after initial response until issue is resolved'
        ],
        responseTime: '10 minutes'
    },
    {
        priority: '2 - High',
        definition: 'Incidents affect a substantial number of users or some of the core functionality may no longer be available to some users of the platform, however the majority of the application capabilities can still be used for their core function.There may be performance degradation that impacts productivity. ',
        level: [
            'Initial response within 2 business hours of incident report to EY through official channel',
            'Best commercial effort during bussiness hours after initial response until acceptable workaround is provided'
        ],
        responseTime: '15 Minutes'
    },
    {
        priority: '3 - Moderate',
        definition: 'Incidents after a moderate number of users and interrupt work to some degree.These incidents relate to non-critical functions or features of the application and a workaround may be available.There may be performance degradation.',
        level: [
            'Initial response within 1 business hours of incident report to EY through official channel',
            'Best commercial effort to provide resolution within 15 bussiness days'
        ],
        responseTime: '20 Minutes'
    },
    {
        priority: '4 - Low',
        definition: 'Incidents that do not interrupt users of the business and can be worked around',
        level: [
            'Initial response within 2 business days of incident report to EY through official channel'
        ],
        responseTime: '30 Minutes'
    },
    {
        priority: 'Availability',
        definition: 'Product is available and accessible to end users',
        level: ['99.5% excluding planned downtime']
    }
];
