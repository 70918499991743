export var InputType;
(function (InputType) {
    InputType["EMAIL"] = "email";
    InputType["NUMBER"] = "number";
    InputType["TEXT"] = "text";
    InputType["PASSWORD"] = "password";
    InputType["TEL"] = "tel";
})(InputType || (InputType = {}));
export var InputElementType;
(function (InputElementType) {
    InputElementType["INPUT"] = "input";
    InputElementType["TEXTAREA"] = "textarea";
    InputElementType["SELECT"] = "select";
    InputElementType["CHIP"] = "chip";
})(InputElementType || (InputElementType = {}));
