export var FileExtensions;
(function (FileExtensions) {
    FileExtensions["Json"] = ".json";
    FileExtensions["XLS"] = ".xls";
    FileExtensions["XLSX"] = ".xlsx";
    FileExtensions["XLSM"] = ".xlsm";
    FileExtensions["DOC"] = ".doc";
    FileExtensions["DOCX"] = ".docx";
    FileExtensions["CSV"] = ".csv";
    FileExtensions["TXT"] = ".txt";
    FileExtensions["XML"] = ".xml";
    FileExtensions["MSG"] = ".msg";
    FileExtensions["ZIP"] = ".zip";
})(FileExtensions || (FileExtensions = {}));
export var FileContentTypes;
(function (FileContentTypes) {
    FileContentTypes["XLSX"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    FileContentTypes["XLSM"] = "application/vnd.ms-excel.sheet.macroenabled.12";
    FileContentTypes["DOC"] = "application/msword";
    FileContentTypes["DOCX"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    FileContentTypes["CSV"] = "text/csv";
    FileContentTypes["TXT"] = "text/plain";
    FileContentTypes["XLS"] = "application/vnd.ms-excel";
    FileContentTypes["XML"] = "application/xml";
    FileContentTypes["ZIP"] = "application/x-zip-compressed";
    FileContentTypes["ZIP_2"] = "application/zip";
    FileContentTypes["MSG"] = "application/vnd.ms-outlook";
    FileContentTypes["PDF"] = "application/pdf";
    FileContentTypes["PNG"] = "image/png";
    FileContentTypes["JPG"] = "image/jpg";
    FileContentTypes["JPEG"] = "image/jpeg";
    FileContentTypes["SVG"] = "image/svg+xml";
    FileContentTypes["TIFF"] = "image/tiff";
    FileContentTypes["RTF"] = "application/rtf";
})(FileContentTypes || (FileContentTypes = {}));
export var FileSizeLimit;
(function (FileSizeLimit) {
    FileSizeLimit["FIVE"] = "5 MB";
    FileSizeLimit["TEN"] = "10 MB";
})(FileSizeLimit || (FileSizeLimit = {}));
