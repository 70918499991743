export var ProgressIndicatorStatus;
(function (ProgressIndicatorStatus) {
    ProgressIndicatorStatus["ACTIVE"] = "active";
    ProgressIndicatorStatus["COMPLETED"] = "completed";
    ProgressIndicatorStatus["DEFAULT"] = "default";
})(ProgressIndicatorStatus || (ProgressIndicatorStatus = {}));
export var ProgressIndicatorVariant;
(function (ProgressIndicatorVariant) {
    ProgressIndicatorVariant["ROW"] = "row";
    ProgressIndicatorVariant["COLUMN"] = "col";
})(ProgressIndicatorVariant || (ProgressIndicatorVariant = {}));
