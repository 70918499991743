var ApiStatus;
(function (ApiStatus) {
    ApiStatus[ApiStatus["NONE"] = 0] = "NONE";
    ApiStatus[ApiStatus["LOADING"] = 1] = "LOADING";
    ApiStatus[ApiStatus["SUCCESS"] = 2] = "SUCCESS";
    ApiStatus[ApiStatus["ERROR"] = 3] = "ERROR";
})(ApiStatus || (ApiStatus = {}));
var HttpHeaderKey;
(function (HttpHeaderKey) {
    HttpHeaderKey["ContentType"] = "Content-Type";
    HttpHeaderKey["ActivePodId"] = "Active-Pod-Id";
    HttpHeaderKey["Authorization"] = "Authorization";
    HttpHeaderKey["ConsistencyLevel"] = "ConsistencyLevel";
    HttpHeaderKey["Tenant"] = "Tenant";
    HttpHeaderKey["Locale"] = "locale";
})(HttpHeaderKey || (HttpHeaderKey = {}));
var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["Success"] = 200] = "Success";
    HttpStatusCode[HttpStatusCode["BadRequest"] = 400] = "BadRequest";
    HttpStatusCode[HttpStatusCode["Unauthorized"] = 401] = "Unauthorized";
    HttpStatusCode[HttpStatusCode["Forbidden"] = 403] = "Forbidden";
    HttpStatusCode[HttpStatusCode["NotFound"] = 404] = "NotFound";
    HttpStatusCode[HttpStatusCode["Conflict"] = 409] = "Conflict";
    HttpStatusCode[HttpStatusCode["InternalServerError"] = 500] = "InternalServerError";
})(HttpStatusCode || (HttpStatusCode = {}));
var HttpHeaderValue;
(function (HttpHeaderValue) {
    HttpHeaderValue["MultipartFormData"] = "multipart/form-data";
    HttpHeaderValue["ApplicationJson"] = "application/json";
    HttpHeaderValue["Eventual"] = "eventual";
})(HttpHeaderValue || (HttpHeaderValue = {}));
export { ApiStatus, HttpStatusCode, HttpHeaderKey, HttpHeaderValue };
